import {API_HOST} from "./envconfig";
import {useKeycloak} from "./keycloak/authentication";

const {getHeaders} = useKeycloak();

/**
 * GET api data.
 * @param path The subpath to query.
 * @param params The query parameters to send.
 */
export const fetchData = async (path, params = null) => {
    const headers = getHeaders();
    headers.Accept = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        const response = await fetch(url, {headers: headers});
        return response.json();
    } catch (e) {
        console.error(url);
        console.error(e);
        alert("An error occurred loading data. Please try again later.");
        window.location.reload();
    }
    return null;
}

/**
 * GET api file.
 * @param path The subpath to query.
 * @param params The query parameters to send.
 */
export const fetchFile = async (path, params = null) => {
    const headers = getHeaders();
    headers["Accept"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        const response = await fetch(url, {headers: headers});
        const blob = await response.blob();
        return window.URL.createObjectURL(new Blob([blob]));
    } catch (e) {
        console.error(url);
        console.error(e);
        alert("An error occurred loading data. Please try again later.");
        window.location.reload();
    }
    return null;
}

/**
 * POST api data.
 * @param path The subpath to query.
 * @param payload The data to send in the body.
 * @param params The query parameters to send.
 */
export const postData = async (path, payload, params = null) => {
    const headers = getHeaders();
    headers["Content-Type"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        return await fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        });
    } catch (e) {
        console.error(e);
        alert("An error occurred sending data. Please try again later.");
        window.location.reload();
    }
    return null;
}

/**
 * PUT api data.
 * @param path The subpath to query.
 * @param payload The data to send in the body.
 * @param params The query parameters to send.
 */
export const putData = async (path, payload, params = null) => {
    const headers = getHeaders();
    headers["Content-Type"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        return await fetch(url, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        });
    } catch (e) {
        console.error(e);
        alert("An error occurred sending data. Please try again later.");
        window.location.reload();
    }
    return null;
}

/**
 * DELETE api data.
 * @param path The subpath to query.
 * @param payload The data to send in the body.
 * @param params The query parameters to send.
 */
export const deleteData = async (path, payload = {}, params = null) => {
    const headers = getHeaders();
    headers["Content-Type"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        return await fetch(url, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        });
    } catch (e) {
        console.error(e);
        alert("An error occurred sending data. Please try again later.");
        window.location.reload();
    }
    return null;
}
