import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {initKeycloak} from "@/keycloak/authentication"

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Sidebar from 'primevue/sidebar';
import Tree from 'primevue/tree';
import Menubar from 'primevue/menubar';
import Card from 'primevue/card';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import Dialog from "primevue/dialog";
import OverlayPanel from "primevue/overlaypanel";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Toolbar from "primevue/toolbar";
import Tooltip from 'primevue/tooltip';
import FileUpload from "primevue/fileupload";
import Message from "primevue/message";
import ScrollPanel from "primevue/scrollpanel";
import Chart from "primevue/chart";
import RadioButton from "primevue/radiobutton";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ConfirmPopup from 'primevue/confirmpopup';

import {
  LMap,
  LControlLayers,
  LControlScale,
  LTileLayer,
  LWmsTileLayer,
  LControl,
  LMarker,
  LGeoJson,
  LCircleMarker,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import LTileLayerBetterWms from "@/components/better-wms/LTileLayerBetterWms";

import 'primevue/resources/themes/saga-blue/theme.css'       // theme
import 'primevue/resources/primevue.min.css'                 // core css
import 'primeicons/primeicons.css'                           // icons
import 'primeflex/primeflex.css'
import 'leaflet-geosearch/assets/css/leaflet.css'            // search box in map

const app = createApp(App)
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);

app.component('Button', Button);
app.component('Toast', Toast);
app.component('Sidebar', Sidebar);
app.component('Tree', Tree);
app.component('Menubar', Menubar);
app.component('Card', Card);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Row', Row);
app.component('Dialog', Dialog);
app.component('OverlayPanel', OverlayPanel);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Dropdown', Dropdown);
app.component('Toolbar', Toolbar);
app.component('FileUpload', FileUpload);
app.component('Message', Message);
app.component('ScrollPanel', ScrollPanel);
app.component('Chart', Chart);
app.component('RadioButton', RadioButton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);

app.component('LMap', LMap);
app.component('LControlLayers', LControlLayers);
app.component('LControlScale', LControlScale);
app.component('LTileLayer', LTileLayer);
app.component('LWmsTileLayer', LWmsTileLayer);
app.component('LControl', LControl);
app.component('LMarker', LMarker);
app.component('LGeoJson', LGeoJson);
app.component('LCircleMarker', LCircleMarker);
app.component('LPopup', LPopup);
app.component('ConfirmPopup', ConfirmPopup);
app.component('LTileLayerBetterWms', LTileLayerBetterWms);

app.directive('tooltip', Tooltip);

initKeycloak({
    checkLoginIframe: false,
    // Check login status.
    onLoad: 'check-sso',
    // Check status in the background, without a full page refresh.
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    enableLogging: true,
}).then(() => {
    // NOTE: Only when keycloak is initialized, the app will actually load. This is done so that we always know the
    // accurate authentication status.
    // If you do not need this, for example because authentication is only a small part of your application, perform
    // the below outside this then-callback.
    app.mount('#app');
})

