<template>
  <Dialog
    :visible="true"
    modal
    dismissable-mask
    :style="{width: '90vw'}"
    @update:visible="hideDialog"
  >
    <template #header>
      <div style="font-size: 30px; font-weight: 600">Contingent: {{ predictionResult.name }}</div>
    </template>

    <Accordion :active-index="[0]" :multiple="true">
      <AccordionTab :header="'Contingent: '+ predictionResult.name ">
        <p>
          Geselecteerd gebied: <strong>{{
            predictionResult.selected_city ? predictionResult.selected_city : 'Nederland'
          }}</strong>
        </p>
        <p>
          Berekening aangemaakt op: <strong>{{ dateTimeToString(predictionResult.created_at) }}</strong>
          <br>Berekening klaar op: <strong>{{ dateTimeToString(predictionResult.finished_at) }}</strong>
          <br>Berekening status: <strong>{{ dateTimeToString(predictionResult.last_status) }}</strong>
        </p>
      </AccordionTab>
      <AccordionTab :header="'Gebruikt training model: '+ predictionResult.training_result.name " :disabled="false">
        <h3>
          Kwaliteit: <span
            :class="'row-badge row-' + qualityValue"
            v-tooltip.right="qualityTooltip"
            style="font-size: 125%;">
          {{ qualityDescription }}
        </span>
        </h3>
        <p>Renovatie Concept: <strong>{{ predictionResult.training_result.renovation_concept.name }}</strong></p>

        <p>Gebruikte label data sets:</p>
        <view-edit-select-table
          :rows="predictionResult.training_result.label_data_sets"
          :columns="ld_columns"
        />

        <p>
          Berekening aangemaakt op: <strong>{{
            dateTimeToString(predictionResult.training_result.training_job.created_at)
          }}</strong>
          <br>Berekening klaar op: <strong>{{
            dateTimeToString(predictionResult.training_result.training_job.finished_at)
          }}</strong>
          <br>Berekening status: <strong>{{
            dateTimeToString(predictionResult.training_result.training_job.last_status)
          }}</strong>
        </p>

        <div style="max-width: 465px">
          <DataTable
            :value="confusionRows"
            show-gridlines
            class="p-datatable-sm"
          >
            <Column
              v-for="col of columns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
              header-style="text-align:center"
              filter-header-style="text-align:center"
              body-style="text-align:center"
            />
          </DataTable>
        </div>
        <h4>Histogram</h4>
        <div style="max-width: 800px">
          <Chart type="bar" :data="histogramData" :options="histogramOptions" />
        </div>
      </AccordionTab>
    </Accordion>
  </Dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted} from "vue";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import {getTrainingResultQuality, getHistogramValues, dateTimeToString} from '@/helpers/functions';

const props = defineProps({
  predictionResult: {
    type: Object,
    required: true,
  }
});

const qualityValue = ref(null);
const qualityDescription = ref(null);
const qualityTooltip = ref(null);
const confusionRows = ref([]);
const positiveRow = ref({'description': 'positieve adressen'});
const negativeRow = ref({'description': 'negatieve adressen'});
const columns = ref([
  {field: 'description', header: 'Confusion matrix'},
  {field: 'actual_positive', header: 'positief voorspeld'},
  {field: 'actual_negative', header: 'negatief voorspeld'},
]);

const histogramData = ref(null);
const histogramOptions = ref(
    {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
);

onMounted(() => {
  let training_result = props.predictionResult.training_result;
  [qualityValue.value, qualityDescription.value, qualityTooltip.value] = getTrainingResultQuality(
      training_result.metrics
  );
  confusionRows.value = [];
  confusionRows.value.push(
      Object.assign({}, positiveRow.value, training_result.metrics.confusion_matrix.positive)
  );
  confusionRows.value.push(
      Object.assign({}, negativeRow.value, training_result.metrics.confusion_matrix.negative)
  )

  let [histogram_bin_centers, histogram_bin_values] = getHistogramValues(
      0, 1, training_result.histogram.bins, training_result.histogram.x
  )

  histogramData.value = {
    labels: histogram_bin_centers,
    datasets: [
      {
        label: 'Adressen',
        backgroundColor: '#42A5F5',
        data: histogram_bin_values
      }
    ]
  };
});

const ld_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'renovation_concept.name', header: 'Renovatie Concept'},
  {field: 'user_initials', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

// const trainingResult = ref(props.trainingResult);

const emit = defineEmits(['close']);

const hideDialog = () => {
  emit("close");
};

</script>

<style scoped>

</style>