export class RenovationConcept {
    constructor(name = "", renovation_measures = []) {
        this.name = name;
        this.renovation_measures = renovation_measures;
    }
}

export class RenovationMeasure {
    constructor(id, name, description, building_part) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.building_part = building_part;
    }
}

export class LabelDataSet {
    constructor(
        name = "",
        label_data_entries = [],
        renovation_concept_id = 0,
        renovation_concept = 0
    ) {
        this.name = name;
        this.label_data_entries = label_data_entries
        this.renovation_concept_id = renovation_concept_id
        this.renovation_concept = renovation_concept
    }
}

export class LabelDataSetPostPut {
    constructor(
        name = "",
        label_data_entries = [],
        renovation_concept_id = 0
    ) {
        this.name = name;
        this.label_data_entries = label_data_entries
        this.renovation_concept_id = renovation_concept_id
    }
}

export class LabelDataEntry {
    constructor(id, postal_code, house_number, house_letter, house_number_annex, applicable, costs,
                energy_savings) {
        this.id = id;
        this.postal_code = postal_code;
        this.house_number = house_number;
        this.house_letter = house_letter;
        this.house_number_annex = house_number_annex;

        this.applicable = applicable;
        this.costs = costs;
        this.energy_savings = energy_savings;
    }
}

export class TrainingSetup {
    constructor(name, renovation_concept_id, label_data_set_id, training_model) {
        this.name = name;
        this.renovation_concept_id = renovation_concept_id;
        this.label_data_set_id = label_data_set_id;
    }
}

export class TrainingResult {
    constructor(params_dict) {
        this.id = params_dict['id'];
        this.name = params_dict['name'];
        this.created = params_dict['created'];
        this.updated = params_dict['updated'];
        this.user_initials = params_dict['user_initials'];

        this.histogram = params_dict['histogram'];
        this.metrics = params_dict['metrics'];
        this.trained_model = params_dict['trained_model'];

        this.training_job = params_dict['training_job'];
        this.renovation_concept = params_dict['renovation_concept'];
        this.label_data_sets = params_dict['label_data_sets'];
    }
}

export const building_parts = Object.freeze({
    WALL: "WALL",
    ROOF: "ROOF",
    FLAT_ROOF: "FLAT_ROOF",
    SLANTED_ROOF: "SLANTED_ROOF",
    FLOOR: "FLOOR",
    WINDOW: "WINDOW",
    WINDOW_FRAME: "WINDOW_FRAME",
    DOOR: "DOOR",
    DOOR_FRAME: "DOOR_FRAME",
    VENTILATION: "VENTILATION",
    HEATING_SYSTEM: "HEATING_SYSTEM",
    COOLING_SYSTEM: "COOLING_SYSTEM",
});
