<template>
  <div class="card">
    <Dialog
      :visible="true"
      :modal="true"
      :closable="false"
      :style="{width: '90vw'}"
      maximizable
      @keydown.enter="hideDialog"
    >
      <template #header>
        <h3>
          <span v-if="isNewLabelDataSet">Voeg label data set toe</span>
          <span v-else>Pas label data set aan</span>
          <help-sidebar
            v-if="helpReference"
            :help-reference="helpReference"
          />
        </h3>
      </template>

      <div class="field">
        <div class="flex flex-row gap-4">
          <div>
            <h5>Naam</h5>
            <input
              v-model="name"
              type="text"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-20rem"
            >
          </div><div>
            <h5>Renovatie Concept</h5>
            <Dropdown
              v-model="selectedRenovationConcept"
              :options="renovationConceptOptions"
              option-label="name"
              option-value="value"
              :placeholder="renConceptPlaceholder"
              class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary"
            />
          </div>
        </div>
        <br>
        <br>
        <label-data-entry-table
          ref="labelEntriesTable"
          :labels="labelDataSet.label_data_entries"
          :data-set-name="'labels_' + name"
          @updateErrors="updateLabelsErrors"
        />
      </div>

      <template #footer>
        <Message v-if="labelsMessage" :severity="labelsMessageSeverity" @close="labelsMessage=null">
          {{ labelsMessage }}
        </Message>
        <div class="flex flex-row-reverse flex-wrap">
          <Button label="Annuleer" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
          <Button
            label="Bevestig" icon="pi pi-check" autofocus
            :disabled="incompleteAdviceText != null || labelsMessageSeverity !== 'success'"
            @click="checkLabels(addUpdateLabelDataSet)"
          />
          <Button
            label="Check" icon="pi pi-sync"
            :disabled="incompleteAdviceText != null"
            :badge="labelsErrorNumber"
            :badge-class="labelsBadgeClass"
            class="p-button-text" @click="checkLabels()"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import {ref, defineEmits, defineProps, onMounted, computed} from "vue";
import {LabelDataSet, LabelDataSetPostPut} from "@/types";
import HelpSidebar from "@/components/help/HelpSidebar"
import LabelDataEntryTable from "@/components/label_data_set/LabelDataEntryTable";

const props = defineProps({
  labelDataSet: {
    type: LabelDataSet,
    required: false,
    default: null,
  },
  renovationConcepts: {
    type: Object,
    required: true,
  },
  helpReference: {
    type: String,
    required: false,
    default: null
  }
});

const labelsErrorNumber = ref("···");
const labelsMessageSeverity = ref("info");
const labelsBadgeClass = ref("p-badge-warning");
const labelsMessage = ref(null);

const labelEntriesTable = ref();
const labelDataSet = ref(props.labelDataSet);
const renovationConcepts = ref(props.renovationConcepts);

const selectedRenovationConcept = ref(null);
const renovationConceptOptions = ref([]);
const fillRenovationConceptsOptions = () => {
  for (let rc of renovationConcepts.value) {
    renovationConceptOptions.value.push(
        {name: rc.name, value: rc}
    )
  }
};
fillRenovationConceptsOptions();

const isNewLabelDataSet = ref(false);
const renConceptPlaceholder = ref('');
if (!labelDataSet.value) {
  isNewLabelDataSet.value = true;
  labelDataSet.value = new LabelDataSet();
  renConceptPlaceholder.value = 'Selecteer renovatie concept';
} else {
  selectedRenovationConcept.value = labelDataSet.value.renovation_concept;
  renConceptPlaceholder.value = selectedRenovationConcept.value.name;
}
const name = ref(null);

const emit = defineEmits(['add', 'update', 'cancel']);

onMounted(() => {
  name.value = labelDataSet.value.name;

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') emit('cancel');
  });
});

const hideDialog = () => {
  emit("cancel");
};

const checkLabels = async (successCallback=null) => {
  labelsMessageSeverity.value = "warn";
  labelsBadgeClass.value = "p-badge-warning";
  labelsErrorNumber.value = "···"
  return await labelEntriesTable.value.checkLabels(successCallback);
}

const addUpdateLabelDataSet = async (nrOfErrors) => {
  const newLabelDataSet = new LabelDataSetPostPut(
      name.value,
      labelDataSet.value.label_data_entries,
      selectedRenovationConcept.value.id
  )
  if (isNewLabelDataSet.value) {
    emit("add", newLabelDataSet);
  } else {
    emit("update", newLabelDataSet);
  }
};

const buttonAdviceText = computed(() => {
  if (incompleteAdviceText.value) {
    return incompleteAdviceText.value;
  }
  if (labelsMessageSeverity.value === "warn") {
    return "Labels aan het valideren ..."
  } else if (labelsMessageSeverity.value === "error") {
    return "Los problemen op"
  }
  return null
});

const incompleteAdviceText = computed(() => {
  if (name.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (!selectedRenovationConcept.value) {
    return "Selecteer een renovatie concept";
  } else if (labelDataSet.value.label_data_entries.length === 0) {
    return "Voeg label data toe";
  }
  return null;
});

const updateLabelsErrors = (numberOfErrorRows, message) => {
  if (numberOfErrorRows === 0) {
    updateLabelsMessage(numberOfErrorRows, "Label data set ok", "success");
  } else {
    updateLabelsMessage(numberOfErrorRows, message, "error");
  }
}

const updateLabelsMessage = (numberOfErrorRows, message, severity) => {
  labelsMessageSeverity.value = severity
  labelsErrorNumber.value = numberOfErrorRows.toString();
  labelsMessage.value = message;
  if (severity === "success") {
    labelsBadgeClass.value = "p-badge-success";
  } else if (severity === "error") {
    labelsBadgeClass.value = "p-badge-danger";
  }
}

</script>

<style scoped>
</style>