<template>
  <Dropdown
    v-if="cities.length"
    v-model="selectedCityId"
    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
          outline-none focus:border-primary w-full"
    option-value="id"
    :filter="true"
    option-label="name"
    placeholder="Selecteer een stad..."
    :options="cities"
    filter-placeholder="Zoek..."
    scroll-height="400px"
    @change="onChange"
  />
</template>

<script setup>
import {defineEmits, defineProps, ref} from "vue";
import {fetchData} from "@/api";

const props = defineProps({
  selected_city: Object
});

const selectedCityId = ref(props.selected_city !== null ? props.selected_city.id : null);
const emit = defineEmits(['update:selected_city']);
const cities = ref([]);

const queryCities = async () => {
  cities.value = await fetchData("region/cities");
}
queryCities();

/**
 * Find the city object based on the ID and emit the entire object.
 * @param e
 * @returns {Promise<void>}
 */
const onChange = async (e) => {
  for (let city of cities.value) {
    if (city.id === selectedCityId.value) {
      emit('update:selected_city', city);
      break;
    }
  }
}

</script>