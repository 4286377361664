<template>
  <div v-if="uiSettings">
    <DetailsZoomLevel v-model:ui-settings="uiSettings" />
  </div>
  <div v-if="uiSettings">
    <ContingentVisualization v-model:ui-settings="uiSettings" />
  </div>

  <Button label="Opslaan" @click="saveSettings"/>
</template>

<script setup>
import { ref } from "vue";
import {fetchData, putData} from "@/api";
import DetailsZoomLevel from '@/components/settings/DetailsZoomLevel'
import ContingentVisualization from "@/components/settings/ContingentVisualization";

const uiSettings = ref(false);

const queryUISettings = async () => {
  const resp = await fetchData("ui_settings/");
  console.log(resp);
  uiSettings.value = resp;
}
queryUISettings();

const saveSettings = async () => {
  console.log("Save settings...");
  console.log(uiSettings.value);
  const resp = await putData("ui_settings/", uiSettings.value);
  let result = await (resp.json());
  console.log(result);
}

</script>

