<template>
  <div>
    <h1>Unauthorized</h1>
    <div>Your account does not have access to the requested page.</div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>
