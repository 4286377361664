<template>
  <DataTable
    v-model:selection="selectedRow"
    :value="props.rows"
    sort-mode="multiple"
    data-key="id"
    class="p-datatable-sm"
    paginator
    :rows="5"
    :rows-per-page-options="[5, 10, 20, 50, 100]"
    @row-select="onSelectRow"
  >
    <template #empty>
      Geen data gevonden
    </template>
    <Column
      v-if="selectableRow"
      selection-mode="single"
      header-style="width: 3em"
      style="max-width: 50px"
    />
    <Column v-if="editableRow || deletableRow || customActions" header="Actie" style="max-width: 100px">
      <template #body="slotProps">
        <ConfirmPopup />
        <i
          v-if="deletableRow"
          v-tooltip="'Verwijderen'"
          class="pi pi-trash px-2"
          @click="deleteRow($event, slotProps.data.id)"
        />
        <i
          v-if="editableRow"
          v-tooltip="'Wijzigen'"
          class="pi pi-pencil px-2"
          @click="editRow(slotProps.data.id)"
        />
        <span v-for="customAction in customActions" :key="customAction.id">
          <i
            v-if="customAction.visible(slotProps.data.id)"
            v-tooltip="customAction.tooltip"
            :class="'pi ' + customAction.icon + ' px-2'"
            @click="customAction.handler(slotProps.data.id)"
          />
        </span>
      </template>
    </Column>
    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :options="col.options"
      sortable
    >
      <template v-if="col.format" #body="slotProps">
        {{ reformat(slotProps.data[col.field], col.format) }}
      </template>
    </Column>
    <Column v-if="badges" :header="badges.name">
      <template #body="slotProps">
        <span :class="'row-badge row-' + badges.values[slotProps.data.id]">
          {{ badges.display_values[slotProps.data.id] }}
        </span>
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted} from "vue";
import { useConfirm } from "primevue/useconfirm";
import {dateTimeToString} from "@/helpers/functions";

const confirm = useConfirm();

const props = defineProps({
  rows: {
    type: Object,
    required: true,
  },
  columns: {
    type: Object,
    required: true,
  },
  editableRow: {
    type: Boolean,
    required: false,
    default: false
  },
  deletableRow: {
    type: Boolean,
    required: false,
    default: false
  },
  selectableRow: {
    type: Boolean,
    required: false,
    default: false
  },
  selectedRow: {
    type: Object,
    required: false,
    default: null
  },
  badges: {
    type: Object,
    required: false,
    default: null
  },
  customActions: {
    type: Object,
    required: false,
    default: null
  }
});

const rows = ref(null);
const columns = ref(null);
const editableRow = ref(null);
const selectableRow = ref(null);
const selectedRow = ref(null);
const customActions = ref(null);
const emit = defineEmits(["select", "edit", "delete", "update:selectedRow"]);

onMounted(() => {
  rows.value = props.rows;
  columns.value = props.columns;
  editableRow.value = props.editableRow;
  selectableRow.value = props.selectableRow;
  selectedRow.value = props.selectedRow;
  customActions.value = props.customActions;
})

const onSelectRow = (event) => {
  emit("update:selectedRow", selectedRow.value);
  emit("select", event.data);
}

const editRow = (row_id) => {
  emit("edit", row_id);
}

const deleteRow = (event, row_id) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Item verwijderen?',
    icon: 'pi pi-exclamation-triangle',
    acceptClass: 'p-button-danger',
    acceptLabel: 'Ja',
    rejectLabel: 'Nee',
    accept: () => {
      emit("delete", row_id);
    }
  });
};

const reformat = (value, format) => {
  if (format === "datetime") {
    return dateTimeToString(value);
  } else {
    return value;
  }
}

</script>

<style scoped>
.ui-datatable-scrollable-header-box table th, .ui-datatable-scrollable-footer-box table th, .ui-datatable-scrollable-body table td{
   width: 20% !important;
}
</style>