<script>
import { onMounted, ref, inject, nextTick } from "vue";
import {
  remapEvents,
  propsBinder,
  WINDOW_OR_GLOBAL,
  GLOBAL_LEAFLET_OPT,
} from "@vue-leaflet/vue-leaflet/src/utils.js";
import { props, setup as betterWmsLayerSetup } from "./tileLayerBetterWms";
import "./L.TileLayer.BetterWMS";

export default {
  props,
  setup(props, context) {
    const leafletRef = ref({});

    const useGlobalLeaflet = inject(GLOBAL_LEAFLET_OPT);
    const addLayer = inject("addLayer");

    const { options, methods } = betterWmsLayerSetup(props, leafletRef);

    onMounted(async () => {
      // eslint-disable-next-line no-unused-vars
      const { tileLayer, DomEvent } = useGlobalLeaflet
        ? WINDOW_OR_GLOBAL.L
        : await import("leaflet/dist/leaflet-src.esm");

      const listeners = remapEvents(context.attrs);
      DomEvent.on(leafletRef.value, listeners);

      leafletRef.value = WINDOW_OR_GLOBAL.L.tileLayer.betterWms(props.baseUrl, options);

      propsBinder(methods, leafletRef.value, props);
      addLayer({
        ...props,
        ...methods,
        leafletObject: leafletRef.value,
      });

      nextTick(() => {
        context.emit("ready", leafletRef.value);
        addEventListener('map-click', (e)=> {
          context.emit("ready", leafletRef.value, e);
        });
      });
    });
    return { leafletObject: leafletRef.value };
  },
  render() {
    return null;
  },
};
</script>