import { props as wmsTileLayerProps, setup as wmsTileLayerSetup } from "@vue-leaflet/vue-leaflet/src/functions/wmsTileLayer";

export const props = {
  ...wmsTileLayerProps,
};

export const setup = (props, leafletRef) => {
  const {
    options: wmsTileLayerOptions,
    methods: wmsTileLayerMethods,
  } = wmsTileLayerSetup(props, leafletRef);
  const options = {
    ...wmsTileLayerOptions,
  };
  return {
    options,
    methods: {
      ...wmsTileLayerMethods,
    },
  };
};