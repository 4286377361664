<template>
  <div class="about">
    <h1>Secure Content</h1>
    <div>Hello {{ firstName }} {{ lastName }}</div>
    <div><pre> {{ tokenInfo }} </pre></div>
    <div>This page is secured by Keycloak and you have the right role</div>
    <div><button @click="logout">Logout</button></div>
  </div>
</template>

<script setup>
import {useKeycloak} from "@/keycloak/authentication";
import {onBeforeMount, ref} from "vue";
const { logout, getKeycloak } = useKeycloak();

const firstName = ref();
const lastName = ref();
const tokenInfo = ref();

onBeforeMount(() => {
  const kc = getKeycloak();

  firstName.value = kc.tokenParsed.given_name
  lastName.value = kc.tokenParsed.family_name
  tokenInfo.value = JSON.stringify(kc.tokenParsed, null, 2);
})
</script>

<style scoped>

</style>
