<template>
  <template v-if="!isAuthenticated">
    <div class="container">
      <img
        src="@/assets/renovation.jpg"
        class="background-image"
      >
      <div class="title-box">
        <h1>Clustertool</h1>
        <h3>Versnellen van de renovatie van de Nederlandse gebouwvoorraad</h3>
        <Button :label="isAuthenticated ? 'Logout': 'Login'" class="p-3" @click="log_in_or_out" />
      </div>
    </div>
  </template>
  <welcome v-else />
</template>

<script setup>
import {useKeycloak} from "@/keycloak/authentication";
import Welcome from "@/views/Welcome";

const { login, logout, isAuthenticated } = useKeycloak();

const log_in_or_out = () => {
  if (isAuthenticated.value) {
    logout();
  } else {
    login();
  }
}

</script>

<style scoped>

.container {
  width: 100%;
  position: relative;
}

.background-image {
  width: 100%;
  height: auto;
  opacity: 0.3;
}

.title-box {
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
}

.title-box h1 {
  font: small-caps 80px Verdana;
}

.title-box h3 {
  font: 20px Verdana;
}

</style>
