<template>
  <div class="about">
    <h1>Secure Content</h1>
    <div>Hello {{ firstName }} {{ lastName }}</div>
    <p>This page is secured by Keycloak</p>
    <div>
      <pre> {{ tokenInfo }} </pre>
    </div>
    <div>
      <button @click="logout">Logout</button>
    </div>
    <div>
      <button @click="apiRequest">Secured API request</button>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue';
import {useKeycloak} from "@/keycloak/authentication";
const {logout, getKeycloak, getHeaders} = useKeycloak();

const firstName = ref();
const lastName = ref();
const tokenInfo = ref();

onBeforeMount(() => {
  const kc = getKeycloak();

  firstName.value = kc.tokenParsed.given_name
  lastName.value = kc.tokenParsed.family_name
  tokenInfo.value = JSON.stringify(kc.tokenParsed, null, 2);
})

const apiRequest = async () => {
  const headers = getHeaders();
  // const queryString = new URLSearchParams({ access_token: token }).toString();
  // const response = await fetch(`http://localhost:9501/secured?${queryString}`);
  const response = await fetch(`http://localhost:9501/secured`, {headers: headers});
  console.log("Secured API response", response);
  const body = await response.text();
  if (response.ok) {
    const msg = "Success: " + body
    console.log(msg);
    alert(msg);
  } else {
    const msg = "Error " + response.status + " " + response.statusText + ": " + body;
    console.error(msg);
    alert(msg);
  }
};
</script>

<style scoped>

</style>
