<template>
  <Dialog
    :visible="true"
    modal
    dismissable-mask
    :style="{width: '90vw'}"
    @update:visible="hideDialog"
  >
    <template #header>
      <div style="font-size: 30px; font-weight: 600">Renovatie Concept: {{ renovationConcept.name }}</div>
    </template>

    <p>
      Concept aangemaakt op: <strong>{{ dateTimeToString(renovationConcept.created) }}</strong>
      <br>Aangemaakt door: <strong>{{ renovationConcept.user_initials }}</strong>
    </p>
    <Accordion :active-index="[]" :multiple="true">
      <AccordionTab :header="'Renovatie Maatregelen: '+ renovationConcept.renovation_measures.length">
        <view-edit-select-table
          :rows="renovationConcept.renovation_measures"
          :columns="measure_columns"
        />
      </AccordionTab>
      <AccordionTab :header="'Label Data Sets: '+ renovationConcept.label_data_sets.length">
        <view-edit-select-table
          :rows="renovationConcept.label_data_sets"
          :columns="label_data_set_columns"
        />
      </AccordionTab>
      <AccordionTab :header="'Getrainde modellen: '+ renovationConcept.training_jobs.length">
        <view-edit-select-table
          :rows="renovationConcept.training_jobs"
          :columns="training_job_columns"
          :badges="trainingJobQualities"
        />
      </AccordionTab>
      <AccordionTab :header="'Contingenten: '+ renovationConcept.prediction_jobs.length">
        <view-edit-select-table
          :rows="renovationConcept.prediction_jobs"
          :columns="prediction_job_columns"
        />
      </AccordionTab>
    </Accordion>
    <slot />
  </Dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted} from "vue";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import {building_parts} from "@/types";
import {dateTimeToString, getTrainingQualities} from "@/helpers/functions";

const props = defineProps({
  renovationConcept: {
    type: Object,
    required: false,
    default: null,
  }
});

const trainingJobQualities = ref(null);

onMounted(() => {
  trainingJobQualities.value = getTrainingQualities(props.renovationConcept.training_jobs);
});

const building_part_options = ref([]);
const setBuildingParts = () => {
  for (let building_part_key in building_parts) {
    building_part_options.value.push({
      label: building_part_key, value: building_parts[building_part_key]
    })
  }
};
setBuildingParts();

const measure_columns = ref([
  {field: 'name', header: 'Naam', options: null},
  {field: 'description', header: 'Beschrijving', options: null},
  {field: 'building_part', header: 'Gebouwonderdeel', options: building_part_options}
]);

const label_data_set_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'renovation_concept.name', header: 'Renovatie Concept'},
  {field: 'nr_of_entries', header: 'Adressen'},
  {field: 'user_initials', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

const training_job_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_initials', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
]);

const prediction_job_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_initials', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
]);

const emit = defineEmits(['close']);

const hideDialog = () => {
  emit("close");
};

</script>

<style scoped>

</style>