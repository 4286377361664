<template>
  <Button
    icon="pi pi-info-circle"
    class="p-button-text p-button-rounded"
    @click="barVisible = true"
  />
  <Sidebar v-model:visible="barVisible" position="right" class="p-sidebar-md" @hide="hideSideBar">
    <h1>{{ helpTexts[helpReference].title }}</h1>
    <span
      v-for="section in helpTexts[helpReference].sections"
      :key="section"
    >
      <h2 v-if="section.title">{{ section.title }}</h2>
      <div
        v-for="paragraph in section.paragraphs"
        :key="paragraph"
        class="help-paragraph"
      >
        {{ paragraph }}
      </div>
    </span>
  </Sidebar>
</template>

<script setup>
import {ref, watch, defineEmits, defineProps} from "vue";
import helpTexts from './help_texts.json';

const props = defineProps({
  // visible: Boolean,
  helpReference: String
});
const barVisible = ref(false);


// watch(() => props.visible, (first, second) => {
//       console.log(
//         "Watch visible.selected function called with args:",
//         first,
//         second
//       );
//       barVisible.value = props.visible;
//     });
// const emit = defineEmits(['update:visible']);

const hideSideBar = () => {
  barVisible.value = false;
  // emit('update:visible', false);
}

</script>

<style>

.help-paragraph {
  margin-top: 10px;
}

</style>