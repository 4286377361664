<template>
  <div class="grid p-2">
    <Card style="width: 100%; margin-top: 1em">
      <template #title>
        Contingent: definieer en start berekening
        <help-sidebar help-reference="Contingent: definieer en start berekening" />
      </template>
      <template #content>
        <label for="naam">Naam</label>
        <input
          id="naam"
          v-model="predictionName"
          type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full"
        >
        <h4>Selecteer een getrained model</h4>
        <view-edit-select-table
          v-model:selected-row="selectedTrainingJob"
          :rows="trainedModels"
          :columns="trainedModelColumns"
          :badges="trainingResultsQualities"
          :custom-actions="trainedModelActions"
          :selectable-row="true"
        />
        <h4>Selecteer gebied waarvoor het contingent bepaald moet worden</h4>
        <div>
          <div class="field-radiobutton">
            <RadioButton v-model="selected_area" input-id="city" name="city" value="city" />
            <label for="city" style="margin-right: 10px;">Stad</label>
            <city-select
              v-if="selected_area === 'city'"
              v-model:selected_city="selected_city"
            />
          </div>
          <!--          <div class="field-radiobutton">-->
          <!--            <RadioButton v-model="selected_area" input-id="province" name="province" value="province" disabled="true" />-->
          <!--            <label for="province">Provincie</label>-->
          <!--            <province-select-->
          <!--              v-if="selected_area === 'province'"-->
          <!--              v-model:selected_province="selected_province"-->
          <!--              style="margin-left: 10px;"-->
          <!--              @update:selectedProvince="(province) => {selected_province.value = province;}"-->
          <!--            />-->
          <!--          </div>-->
          <div class="field-radiobutton">
            <RadioButton v-model="selected_area" input-id="country" name="country" value="country" />
            <label for="country">Heel Nederland</label>
          </div>
        </div>
        <div v-if="hasRole('admin')">
          <h4>Selecteer predictiemodel</h4>
          <Dropdown
              v-model="selectedPredictionModelId"
              :options="predictionModels"
              option-label="name"
              option-value="id"
              class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            class="flex align-items-center justify-content-center m-2"
            label="Start berekening"
            icon="pi pi-play"
            :disabled="buttonAdviceText != null"
            @click="onStartCalculation"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Card>

    <Card style="width: 100%; margin-top: 1em">
      <template #title>
        Contingent: lopende berekeningen en resultaten
        <help-sidebar help-reference="Contingent: lopende/voltooide berekeningen" />
      </template>
      <template #content>
        <view-edit-select-table
          :rows="predictionJobs"
          :columns="predictionJobsColumns"
          :deletable-row="true"
          :custom-actions="jobActions"
          @delete="onRemovePredictionJob"
        />
      </template>
    </Card>
    <view-training-details
      v-if="trainedModelToView!==null"
      :training-job="trainedModelToView"
      @close="trainedModelToView=null"
    />
    <view-prediction-details
      v-if="predictionResultToView!==null"
      :prediction-result="predictionResultToView"
      @close="predictionResultToView=null"
    />
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import HelpSidebar from "@/components/help/HelpSidebar"
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import CitySelect from "@/components/base-components/CitySelect";
// import ProvinceSelect from "@/components/base-components/ProvinceSelect";
import {deleteData, fetchData, fetchFile, postData} from "@/api";
import router from "@/router";
import {useToast} from "primevue/usetoast";
import {exportFileFromBloburl, getTrainingQualities} from "@/helpers/functions";
import ViewTrainingDetails from "@/components/training/ViewTrainingDetails";
import ViewPredictionDetails from "@/components/prediction/ViewPredictionDetails";
import {useKeycloak} from "@/keycloak/authentication";

const {hasRole} = useKeycloak();

const toast = useToast();

const predictionName = ref("");
const selectedTrainingJob = ref();
const trainedModelToView = ref(null);
const trainedModels = ref([]);
const trainedModelColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_initials', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);
const trainingResultsQualities = ref(null);

const predictionJobs = ref([]);
const predictionResultToView = ref(null);

const predictionModels = ref([]);
const selectedPredictionModelId = ref(null);

const selected_area = ref("city");
const selected_city = ref(null);
// const selected_province = ref("");

const predictionJobsColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_initials', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
]);

const queryTrainedModels = async () => {
  const training_jobs = await fetchData("training/job");
  // Only show training jobs with results and within quality limit
  trainedModels.value = training_jobs.filter((job) => {
    return job.training_result != null &&
        job.training_result.metrics.accuracy_class > 0;
  });
  trainingResultsQualities.value = getTrainingQualities(trainedModels.value);
};
queryTrainedModels();

const queryPredictionModels = async () => {
  predictionModels.value = await fetchData("prediction/models");
  if (predictionModels.value.length > 0) {
    selectedPredictionModelId.value = predictionModels.value[0].id;
  }
};
if (hasRole('admin')) {
  queryPredictionModels();
}

const buttonAdviceText = computed(() => {
  if (predictionName.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (!selectedTrainingJob.value) {
    return "Selecteer een getrained model";
  } else if (selected_area.value === "city" && !selected_city.value) {
    return "Selecteer een gebied";
  }
  return null;
});

const onStartCalculation = async () => {
  let payload = {
    name: predictionName.value,
    training_result_id: selectedTrainingJob.value.training_result.id,
  }
  if (selected_city.value) {
    payload['selected_city'] = selected_city.value.name;
  }

  // create training setup in db
  const ps_post = await postData("prediction/setup", payload);
  const ps_resp = await ps_post.json();

  if (ps_resp === null) {
    return;
  }

  // create and start prediction job
  await postData("prediction/job", {
    prediction_setup_id: ps_resp.id,
    prediction_model_id: selectedPredictionModelId.value,
  });

  toast.add({
    severity: 'success',
    summary: "Contingent berekening '" + predictionName.value + "' toegevoegd",
    detail: "Getraind model: '" + selectedTrainingJob.value.name,
    life: 5000
  });
  predictionName.value = '';
  await queryPredictionJobs();
};

/**
 * Query prediction jobs from backend.
 * @returns {Promise<void>}
 */
const queryPredictionJobs = async () => {
  predictionJobs.value = await fetchData("prediction/job");
};
queryPredictionJobs();

const onRemovePredictionJob = async (predictionJobId) => {
  let jobName = null;
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId) {
      jobName = predictionJob.name;
    }
  }
  const del_resp = await deleteData("prediction/job/" + predictionJobId);
  await queryPredictionJobs();

  if (del_resp.ok) {
    toast.add({
      severity: 'success',
      summary: "Contingent '" + jobName + "' verwijderd",
      life: 5000
    });
  } else {
    toast.add({
      severity: 'error',
      summary: "Contingent '" + jobName + "' kan niet verwijderd worden",
      life: 5000
    });
  }
};

/**
 * Check status of job. This only checks the status in the API. It does not go to Kubeflow, because loading the results
 * can take too long. The jobchecker will check this.
 *
 */
const onCheckStatus = async (prediction_job_id) => {
  const prediction_job = await fetchData("prediction/job/" + prediction_job_id);

  const severity_map = {
    'Succeeded': 'success',
    'Failed': 'error'
  }
  let severity = 'warn'
  if (prediction_job.last_status in severity_map) {
    severity = severity_map[prediction_job.last_status];
  }
  toast.add({
    severity: severity,
    summary: "Model prediction job status: " + prediction_job.last_status,
    detail: "Created: " + prediction_job.created_at +
        "\n\nScheduled: " + prediction_job.scheduled_at +
        "\n\nFinished: " + prediction_job.finished_at,
    life: 5000
  });
  await queryPredictionJobs();
};

const onDownloadList = async (predictionJobId) => {
  const contingentId = getContingentId(predictionJobId);
  if (contingentId) {
    let pred_name = '';
    for (const job of predictionJobs.value) {
      if (job.contingent_id === contingentId) {
        pred_name = job.name;
      }
    }
    const blobUrl = await fetchFile("prediction/contingent_csv/" + contingentId);
    exportFileFromBloburl(blobUrl, `${pred_name}.csv`)
  }
};

/**
 * Show results of prediction job on map. This will only work if the prediction job is finished.
 * @param predictionJobId
 */
const onShowResults = (predictionJobId) => {
  const contingentId = getContingentId(predictionJobId);
  if (contingentId) {
    router.push({
      name: 'MapResults',
      params: {contingentId: contingentId},
    });
  }
};

/**
 * Get the contingent id of a prediction job. This is used to download the results.
 * @param predictionJobId
 */
const getContingentId = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId) {
      if ('prediction_result' in predictionJob && predictionJob.prediction_result !== null) {
        return predictionJob.prediction_result.id;
      }
    }
  }
  return null;
};

/**
 * Get the training job id of a prediction job. This is used to download the results.
 * @param trainingJobId
 */
const onViewTrainingDetails = async (trainingJobId) => {
  trainedModelToView.value = await fetchData("training/job/" + trainingJobId);
};

const onViewPredictionDetails = async (trainingJobId) => {
  predictionResultToView.value = (predictionResultToView.value = await fetchData("prediction/job_result/" + trainingJobId));
};

const jobSucceeded = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId && predictionJob.last_status !== null) {
      return predictionJob.last_status.toUpperCase() === 'SUCCEEDED';
    }
  }
  return false;
};

const jobIsRunning = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId && predictionJob.last_status !== null) {
      return predictionJob.last_status.toUpperCase() === 'CREATED' || predictionJob.last_status.toUpperCase() === 'RUNNING';
    }
  }
  return false;
};

const trainedModelActions = [
  {
    id: 1, icon: 'pi pi-eye', visible: () => true, handler: onViewTrainingDetails, tooltip: 'Bekijk details'
  }
];

const jobActions = [
  {
    id: 1, icon: 'pi pi-eye', visible: () => true, handler: onViewPredictionDetails, tooltip: 'Bekijk details'
  },
  {
    id: 2, icon: 'pi-sync', visible: jobIsRunning, handler: onCheckStatus, tooltip: 'Check status'
  },
  {
    id: 3, icon: 'pi-download', visible: jobSucceeded, handler: onDownloadList, tooltip: 'Download adressen'
  },
  {
    id: 4, icon: 'pi-map', visible: jobSucceeded, handler: onShowResults, tooltip: 'Bekijk op kaart'
  }
];

</script>

<style scoped>

</style>
