<template>
  <div style="height: 100%">
    <Splitter layout="vertical">
      <SplitterPanel :size="80">
        <Splitter style="height: 1000px">
          <SplitterPanel :size="30">
            <div style="padding-left: 1rem">
              <h3 v-if="predictionInfo" >
              {{ predictionInfo.name }}
              <help-sidebar help-reference="Resultaten" />
              </h3>
              <h4 v-else>
                Geen contingent geselecteerd, ga naar het vorige tabblad
              </h4>
            </div>
            <Accordion :active-index="activeInfoTabIndex" :multiple="true">
              <AccordionTab header="Contingent informatie">
                <Accordion :active-index="activeInfoSubTabIndex1" :multiple="true">
                  <AccordionTab header="Algemene informatie">
                    <!--                <DataTable v-if="contingentInfoRows" :value="contingentInfoRows" responsive-layout="scroll" class="p-datatable-sm">-->
                    <!--                  <Column field="descr" header-style="display: none;" />-->
                    <!--                  <Column field="value" header-style="display: none;" />-->
                    <!--                </DataTable>-->
                    <div v-if="contingentId">
                      <DataTable
                        v-if="contingentInfoRows"
                        :value="contingentInfoRows"
                        responsive-layout="scroll"
                        class="p-datatable-sm"
                      >
                        <Column field="descr" header-style="display: none;" />
                        <Column field="value" header-style="display: none;" />
                      </DataTable>
                    </div>
                    <router-link v-else to="/determine_contingent">
                      Selecteer een contingent bij 'Contingent bepalen'
                    </router-link>
                  </AccordionTab>
                  <AccordionTab v-if="contingentId" header="Distributie van de geschiktheid">
                    <Chart type="bar" :data="histogramContingentSuitable" :options="histogramOptions" />
                  </AccordionTab>
                  <AccordionTab v-if="contingentId" header="Bouwjaardistributie">
                    <Chart type="bar" :data="histogramContingentYear" :options="histogramOptions" />
                  </AccordionTab>
                </Accordion>
              </AccordionTab>
              <AccordionTab header="Informatie over aangeklikt object" :disabled="false">
                <template
                  v-if="objectInfoRows"
                >
                  <DataTable :value="objectInfoRows" responsive-layout="scroll" class="p-datatable-sm">
                    <Column field="descr" header-style="display: none;" />
                    <Column field="value" header-style="display: none;" />
                  </DataTable>
                  <div v-if="histogramRegionData" style="max-width: 800px">
                    <Chart type="bar" :data="histogramRegionData" :options="histogramOptions" />
                  </div>
                </template>
                <div v-else>- Klik op een object op de kaart -</div>
                <a v-if="clicked_street_picture_url" :href="clicked_maps_url" target="_blank">
                  <img :src="clicked_street_picture_url" alt="">
                </a>
              </AccordionTab>
            </Accordion>
          </SplitterPanel>
          <SplitterPanel :size="70">
            <select
              v-if="color_options.length"
              v-model="chosen_parameter"
              class="block w-full mt-1"
              @change="chooseOtherParameter"
            >
              <option
                v-for="option in color_options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
            <l-map
              v-if="uiSettings"
              v-model:zoom="zoom"
              :zoom-animation=true
              :center="center"
              @ready="onMapReady"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                name="OpenStreetMap"
                layer-type="base"
                :max-native-zoom="18"
                :max-zoom="22"
                attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
              />
              <template
                v-if="contingentId != null && (zoom >= uiSettings.min_zoom_level_building && zoom < uiSettings.max_zoom_level_building)"
              >
                <l-tile-layer-better-wms
                  id="building_better_wms"
                  :base-url="GEOSERVER_HOST + '/geoserver/clustertool/wms'"
                  name="clustertool"
                  format="image/png"
                  :transparent="true"
                  :layers="'contingent_' + contingentId"
                  @ready="clickBuilding"
                />
                <l-control position="bottomright">
                  <img :src="GEOSERVER_HOST + '/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=40&HEIGHT=20&LAYER=clustertool:contingent_' + contingentId">
                </l-control>
              </template>
              <!--              <l-geo-json-->
              <!--                v-if="geojson && zoom >= uiSettings.min_zoom_level_building && zoom < uiSettings.max_zoom_level_building"-->
              <!--                ref="geojsonLayer"-->
              <!--                :geojson="geojson"-->
              <!--                :options="geojsonOptions"-->
              <!--              />-->
              <!--                v-if="geojsonClicked && zoom >= uiSettings.min_zoom_level_building && zoom < uiSettings.max_zoom_level_building"-->
              <l-geo-json
                v-if="geojsonClicked"
                ref="geojsonLayer"
                :geojson="geojsonClicked"
                :options="geojsonClickedOptions"
              />
              <!--          <l-wms-tile-layer-->
              <!--                v-if="contingentId || (false && zoom >= uiSettings.min_zoom_level_building && zoom < uiSettings.max_zoom_level_building)"-->
              <!--                base-url="http://localhost:9581/geoserver/clustertool/wms"-->
              <!--                name="clustertool"-->
              <!--                format="image/png"-->
              <!--                :transparent="true"-->
              <!--                :layers="'contingent_' + contingentId"-->
              <!--              />-->
              <!--              <l-circle-marker v-if="pointLatlng" ref="marker" :lat-lng="pointLatlng" :radius="0" :opacity="0">-->
              <!--                <l-popup ref="popup" :content="pointInfo" />-->
              <!--              </l-circle-marker>-->
              <l-marker v-if="pointLatlng" :lat-lng="pointLatlng" />

              <template
                v-if="contingentId != null && (zoom >= uiSettings.min_zoom_level_pc6 && zoom <= uiSettings.max_zoom_level_pc6)"
              >
                <!--                <l-wms-tile-layer-->
                <!--                  base-url="http://localhost:9581/geoserver/clustertool/wms"-->
                <!--                  name="clustertool"-->
                <!--                  format="image/png"-->
                <!--                  :transparent="true"-->
                <!--                  styles="Contingent 5"-->
                <!--                  :layers="'PC6_aggr_' + contingentId"-->
                <!--                />-->
                <l-tile-layer-better-wms
                  :base-url="GEOSERVER_HOST + '/geoserver/clustertool/wms'"
                  name="clustertool"
                  format="image/png"
                  :transparent="true"
                  :layers="'PC6_aggr_' + contingentId"
                  @ready="clickAggregatedArea"
                />
                <l-control position="bottomright">
                  <img :src="GEOSERVER_HOST + '/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=40&HEIGHT=20&LAYER=clustertool:PC6_aggr_' + contingentId">
                </l-control>
              </template>
              <template
                v-if="contingentId != null && (zoom >= uiSettings.min_zoom_level_pc5 && zoom <= uiSettings.max_zoom_level_pc5)"
              >
                <!--                <l-wms-tile-layer-->
                <!--                  base-url="http://localhost:9581/geoserver/clustertool/wms"-->
                <!--                  name="clustertool"-->
                <!--                  format="image/png"-->
                <!--                  :transparent="true"-->
                <!--                  styles="Contingent 5"-->
                <!--                  :layers="'PC5_aggr_' + contingentId"-->
                <!--                />-->
                <l-tile-layer-better-wms
                  id="pc5_better_wms"
                  :base-url="GEOSERVER_HOST + '/geoserver/clustertool/wms'"
                  name="clustertool"
                  format="image/png"
                  :transparent="true"
                  :layers="'PC5_aggr_' + contingentId"
                  @ready="clickAggregatedArea"
                />
                <l-control position="bottomright">
                  <img :src="GEOSERVER_HOST + '/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=40&HEIGHT=20&LAYER=clustertool:PC5_aggr_' + contingentId">
                </l-control>
              </template>
              <template
                v-if="contingentId != null && (zoom >= uiSettings.min_zoom_level_pc4 && zoom <= uiSettings.max_zoom_level_pc4)"
              >
                <!--                <l-wms-tile-layer-->
                <!--                  :base-url="GEOSERVER_HOST + '/geoserver/clustertool/wms'"-->
                <!--                  name="clustertool"-->
                <!--                  format="image/png"-->
                <!--                  :transparent="true"-->
                <!--                  styles="Contingent 5"-->
                <!--                  :layers="'PC4_aggr_' + contingentId"-->
                <!--                />-->
                <l-tile-layer-better-wms
                  :base-url="GEOSERVER_HOST + '/geoserver/clustertool/wms'"
                  name="clustertool"
                  format="image/png"
                  :transparent="true"
                  :layers="'PC4_aggr_' + contingentId"
                  @ready="clickAggregatedArea"
                />
                <l-control position="bottomright">
                  <img :src="GEOSERVER_HOST + '/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=40&HEIGHT=20&LAYER=clustertool:PC4_aggr_' + contingentId">
                </l-control>
              </template>
            </l-map>
          </SplitterPanel>
        </Splitter>
      </SplitterPanel>
      <SplitterPanel :size="20">
        <div>Zoom-level: {{ zoom }}</div>
        <div>Center: {{ center }}</div>
        <div
          v-if="uiSettings"
        >
          Aggregation:
          <span
            v-if="zoom >= uiSettings.min_zoom_level_building && zoom < uiSettings.max_zoom_level_building"
          >
            buildings
          </span>
          <span
            v-if="zoom >= uiSettings.min_zoom_level_pc4 && zoom <= uiSettings.max_zoom_level_pc4"
          >
            PC4
          </span>
          <span
            v-if="zoom >= uiSettings.min_zoom_level_pc5 && zoom <= uiSettings.max_zoom_level_pc5"
          >
            PC5
          </span>
          <span
            v-if="zoom >= uiSettings.min_zoom_level_pc6 && zoom <= uiSettings.max_zoom_level_pc6"
          >
            PC6
          </span>
        </div>
        <div
          v-if="uiSettings"
        >
          Contingent ID:
          <span
            v-if="contingentId != null"
          >
            {{ contingentId }}
          </span>
        </div>
      </SplitterPanel>
    </Splitter>
  </div>
</template>

<script setup>
import {ref, nextTick, onMounted} from "vue";
import {useRoute} from "vue-router";
import {fetchData} from "@/api";
import {GEOSERVER_HOST} from "@/envconfig";
import HelpSidebar from "@/components/help/HelpSidebar"
import {dateTimeToString, roundToNrOfDecimals} from "@/helpers/functions";

// Import order is important here. Import GeoSearch control first. Then, import Leaflet.
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
import {Icon} from "leaflet";

const uiSettings = ref();

const nlCenter = [52.0118, 4.35938];
const defaultZoom = 8;
const center = ref(nlCenter);
const zoom = ref(defaultZoom);
const map = ref(null);
const geojson = ref(null);
const geojsonLayer = ref(null);
const geojsonClicked = ref(null);
const clicked_maps_url = ref(null);
const clicked_street_picture_url = ref(null);

var values = {};
var buckets = {};
const color_options = ref([]);
const chosen_parameter = ref(null);

const route = useRoute()
const contingentId = route.params.contingentId;

const queryUISettings = async () => {
  const resp = await fetchData("ui_settings/");
  uiSettings.value = resp;
}
queryUISettings();

const pointLatlng = ref(null);

const marker = ref(null);

const activeInfoTabIndex = ref([0,1]);
const activeInfoSubTabIndex1 = ref([0,1]);
const activeInfoSubTabIndex2 = ref([0,1]);
const contingentInfoRows = ref(null);
const objectInfoRows = ref(null);

const predictionInfo = ref(null);
const predictionInfoRows = ref(null);

const histogramRegionData = ref(null);
const histogramContingentYear = ref(null);
const histogramContingentSuitable = ref(null);
const histogramOptions = ref(null);

onMounted(async () => {
  if (contingentId) {
    predictionInfo.value = await fetchData("prediction/result/" + contingentId);
    contingentInfoRows.value = [];
    contingentInfoRows.value.push({'descr': 'ID', 'value': contingentId});
    contingentInfoRows.value.push({'descr': 'Naam', 'value': predictionInfo.value.name});
    contingentInfoRows.value.push({'descr': 'Aangemaakt op', 'value': dateTimeToString(predictionInfo.value.created)});

    let contingentBuckets = await fetchData("statistics/aggregated_data/" + contingentId);
    // console.log(await contingentBuckets);
    histogramContingentYear.value = contingentBuckets;

    contingentBuckets = await fetchData("statistics/contingent/" + contingentId);
    // console.log(await contingentBuckets);
    histogramContingentSuitable.value = contingentBuckets;
  }
});

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
function hslToRgb(h, s, l) {
  let r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    let hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function toHex(d) {
  return ("0" + (Number(Math.round(d)).toString(16))).slice(-2).toUpperCase()
}

// Adopted from https://stackoverflow.com/questions/17525215/calculate-color-values-from-green-to-red
function numberToColorRGB(i) {
  let hue = i * 1.2 / 3.60;         // Determines from red to green
  let rgb = hslToRgb(hue, 1, .5);   // Bright colors
  return '#' + toHex(rgb[0]) + toHex(rgb[1]) + toHex(rgb[2]);
}

function onEachFeature(feature, layer) {
  // layer.bindPopup("Test popup");
  layer.on("click", (e) => {
    console.log(e, feature.properties.id);
  });
}

const setBuildingStyle = (feature) => {
  let value = feature.properties[chosen_parameter.value];
  let color = "#000000";
  if (value) {
    let bucket_pos = buckets[chosen_parameter.value].length;         // start with last bucket position
    for (let i = 0; i < buckets[chosen_parameter.value].length; i++) {
      if (value < buckets[chosen_parameter.value][i]) {
        bucket_pos = i;                                 // find out if value is in 'earlier' bucket
        break;
      }
    }

    let val = bucket_pos / buckets[chosen_parameter.value].length;
    color = numberToColorRGB((1 - val), 0, 1);
  }
  return {
    weight: 2,
    color: color,
    fillColor: color,
    fillOpacity: 0.6,
  }
}

let geojsonOptions = {
  style: setBuildingStyle,
  onEachFeature: onEachFeature,
};

let geojsonClickedOptions = {
  style: {
    weight: 5,
    color: "#ffffff"   // numberToColorRGB(0, 1, 0),
  },
  onEachFeature: onEachFeature,
};

const fetchBuildingData = async (params) => {
  let geojson_result = await fetchData(`bag/geojson`,
      params
  );

  // Create a list with all coordinates, that can be used as a parameter to the fitBounds method in order to zoom
  // to the buildings represented in the geojson layers. There must be a nicer way however...
  let coords = [];
  geojson_result.features.forEach(ftr => {
    let props = Object.keys(ftr.properties);
    for (const prop of props) {
      if (!(prop in values)) {
        values[prop] = [ftr.properties[prop]];
      } else {
        values[prop].push(ftr.properties[prop])
      }
    }

    ftr.geometry.coordinates.forEach(pol => {
      // TODO: Find out if BAG contains any multi polygons
      // pol.forEach(coord_list => {
      //   coord_list.forEach(coord => {
      //     // switch coordinates as GeoJSON and Leaflet use different orderings
      //     coords.push([coord[1], coord[0]]);
      //   });
      // });
      pol.forEach(coord => {
        // switch coordinates as GeoJSON and Leaflet use different orderings
        coords.push([coord[1], coord[0]]);
      });
    });
  });

  for (const prop of Object.keys(values)) {
    if (prop === 'id') {
      continue;
    }
    values[prop].sort(function (a, b) {
      return a - b;
    });        // sort all values
    buckets[prop] = [];
    for (let i = 1; i < 6; i++) {   // divide values into 6 equally sized buckets
      buckets[prop].push(values[prop][Number((values[prop].length / 6 * i).toFixed(0))]);
    }
    if (prop !== "id") {
      color_options.value.push({label: prop, value: prop});
    }
  }
  chosen_parameter.value = color_options.value[0].value;

  geojson.value = geojson_result;
  return coords;
};

// eslint-disable-next-line no-unused-vars
const chooseOtherParameter = async (e) => {
  let geojson_tmp = geojson.value;
  geojson.value = null;
  await nextTick();
  geojson.value = geojson_tmp;
}

const onMapReady = async (map_ref) => {
  map.value = map_ref;
  // let coords = await fetchBuildingData({});
  // map.value.fitBounds(coords, { padding: [20, 20] });

  const provider = new OpenStreetMapProvider({
    params: {
      'accept-language': 'nl', // render results in Dutch
      countrycodes: 'nl', // limit search results to the Netherlands
    },
  });
  const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'bar',
    showMarker: true,
    marker: {
      icon: new Icon.Default(),
      draggable: false,
    },
    retainZoomLevel: true,  // Setting this to false breaks the search panning
    searchLabel: 'Zoek hier',
    animateZoom: true,
  });
  map.value.addControl(searchControl);

  zoomToWMSLayer();
};

const zoomToWMSLayer = async () => {
  if (contingentId !== undefined) {
    let res = await fetchData('prediction/result/' + contingentId + '/wms_bb');
    map.value.fitBounds(res['bounding_box'], {padding: [20, 20]})
  }
}

const clickBuilding = async (leafletRef, e) => {
  if (zoom.value < uiSettings.value.min_zoom_level_building) {
    console.log("clickBuilding was called while buildings were NOT visible")
    return;
  }
  if (e && e.info.features[0]) {
    const objectProperties = e.info.features[0].properties;
    console.log(objectProperties);

    geojsonClicked.value = await fetchData("bag/geojson/" + objectProperties.vbo_pand_id);
    console.log("objectProperties building");
    console.log(objectProperties);

    const featureProps = geojsonClicked.value.features[0].properties;

    let lats = [];
    let lons = [];
    geojsonClicked.value.features[0].geometry.coordinates.forEach(pol => {
      pol.forEach(coord => {
        // switch coordinates as GeoJSON and Leaflet use different orderings
        lats.push(coord[1]);
        lons.push(coord[0]);
      });
    });
    pointLatlng.value = [
      (Math.max.apply(null, lats) + Math.min.apply(null, lats)) / 2,
      (Math.max.apply(null, lons) + Math.min.apply(null, lons)) / 2
    ];
    // console.log(pointLatlng)

    objectInfoRows.value = [];
    const suitable = roundToNrOfDecimals(objectProperties.suitable, 2);
    const huisletter = featureProps.huisletter ? featureProps.huisletter : '';
    const toevoeging = featureProps.toevoeging ? featureProps.toevoeging : '';
    objectInfoRows.value.push({'descr': 'Toepasbaarheid', 'value': `${suitable}  `});
    objectInfoRows.value.push({
      'descr': 'Adres regel 1',
      'value': `${featureProps.straat} ${featureProps.huisnummer} ${huisletter} ${toevoeging}`
    });
    objectInfoRows.value.push({'descr': 'Adres regel 2', 'value': `${featureProps.postcode} ${featureProps.woonplaats}`});
    objectInfoRows.value.push({'descr': 'Bouwjaar', 'value': `${featureProps.bouwjaar}`});
    objectInfoRows.value.push({'descr': 'Oppervlakte', 'value': `${Math.round(featureProps.oppervlak * 100) / 100} m2`});
    objectInfoRows.value.push({'descr': 'Omtrek', 'value': `${Math.round(featureProps.omtrek * 100) / 100} m`});

    clicked_maps_url.value = featureProps.maps_url;
    // console.log(clicked_maps_url.value);
    clicked_street_picture_url.value = featureProps.street_picture_url;
    // console.log(clicked_street_picture_url.value);

    activeInfoTabIndex.value = [1];
    // let coords = await fetchBuildingData({});
    // console.log(coords)
    // map.value.fitBounds(coords, { padding: [20, 20] });
  } else {
    geojsonClicked.value = null;
    pointLatlng.value = null;
    objectInfoRows.value = null;
    clicked_maps_url.value = null;
    clicked_street_picture_url.value = null;
    activeInfoTabIndex.value = [0];
  }
}


const clickAggregatedArea = async (leafletRef, e) => {
  if (zoom.value >= uiSettings.value.min_zoom_level_building) {
    console.log("clickAggregatedArea was called while buildings were visible")
    return;
  }

  clicked_maps_url.value = null;
  clicked_street_picture_url.value = null;

  if (e && e.info.features[0]) {
    objectInfoRows.value = null;
    histogramRegionData.value = null;

    const objectProperties = e.info.features[0].properties;
    geojsonClicked.value = await fetchData("region/geojson/" + objectProperties.id);

    let buildingYearBuckets = await fetchData("statistics/aggregated_data/" + contingentId + "/" + objectProperties.id);
    // console.log(await buildingYearBuckets);
    histogramRegionData.value = buildingYearBuckets;

    objectInfoRows.value = [];
    objectInfoRows.value.push({'descr': 'Gebiedscode', 'value': objectProperties.id});
    objectInfoRows.value.push({'descr': 'Toepasbaarheid', 'value': `${Math.round(objectProperties.suitable * 100) / 100}`});
    objectInfoRows.value.push({'descr': 'Totaal aantal woningen', 'value': objectProperties.tot_num_vbos});
    objectInfoRows.value.push({'descr': 'Aantal woningen in contingent', 'value': objectProperties.num_cont_vbos});
    objectInfoRows.value.push({'descr': 'Aantal inwoners', 'value': objectProperties.num_people});

    activeInfoTabIndex.value = [1];
  } else {
    activeInfoTabIndex.value = [0];
    objectInfoRows.value = null;
    histogramRegionData.value = null;
    geojsonClicked.value = null;
  }
}


</script>

<style lang="scss" scoped>

//.p-splitter-panel {
//  overflow: scroll;
//}

.leaflet-container {
  z-index: 100;
}

</style>
